<template>
    <div>
        <div v-if="has_carousel && !get_selected_location.video_id">
            <carousel
              :perPage=1
              :autoplay=true
              :autoplayTimeout=3000
              :paginationEnabled=true
              :loop=true
            >
                <slide v-for="(image, index) in get_selected_location.images_header" :key="'image-'+index">
                    <div class="banner page_banner" :style="carouselImage(image)">
					</div>
                </slide>
            </carousel>
        </div>
        <iframe v-if="get_selected_location.video_id" :src="'https://www.youtube.com/embed/'+get_selected_location.video_id" frameborder="0" allow="autoplay; encrypted-media; picture-in-picture" allowfullscreen class="yt-video"></iframe>
        <div class="banner page_banner" :style="banner" v-if="!has_carousel && !get_selected_location.video_id && get_selected_location.images_header"></div>
        <div class="banner page_banner" style="display:flex;justify-content:center;align-items:center" v-if="!get_selected_location.images_header && !get_selected_location.video_id">
          <img src="images/loader.gif" alt="">
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "Itembanner",
  components: {
    Carousel,
    Slide
  },
  methods: {
	carouselImage(img){
		let w = 760;
		let h = 0;
		let url = 'https://cdn.basisam.nl/images/'+this.get_config.images_cdn+'/'+w+'x'+h+'/'+img;
		let rurl = 'https://cdn.basisam.nl/images/'+this.get_config.images_cdn+'/'+w*2+'x'+h*2+'/'+img;

		return `background-image: url("${url}");
			background-image: -webkit-image-set(url("${url}") 1x, url("${rurl}") 2x);
			background-image: image-set(url("${url}") 1x, url("${rurl}") 2x);`;
	}
  },
  computed: {
     ...mapGetters([
      "get_config",
      "get_selected_location",
      'get_image_size_multiplier',
    ]),
    has_carousel(){
      if(this.get_selected_location.hasOwnProperty('images_header')){
        if(this.get_selected_location.images_header.length > 1){
          return true;
        }
      }
      return false;
    },
    banner() {
		return this.carouselImage(this.get_selected_location.imglarge);
    },
  }
};
</script>

<style>
.VueCarousel{
  margin-top:5px;
}
.VueCarousel-pagination{
  position:absolute;
  bottom:0;
}
.VueCarousel-dot{
  outline:none !important;
}
.VueCarousel-dot--active{
  background-color: black !important;
}
@media only screen and (max-width:900px){
  .VueCarousel{
    width:100%;
    height:auto;
  }
}
</style>

