<template>
  <div class="block footer" ref="Footer">
    <div class="container">
      <div class="text-center footer_tag" style="font-size: 13px; line-height: 1.4em">
        <a target="_blank" href="/privacyverklaring.pdf"><u>Privacystatement</u></a><br>
        <div style="height:1em"></div>
        © {{currentYear}}. <span @click="details = !details" style="cursor:pointer"><u>Klik hier</u></span> voor adresgegevens. Alle rechten voorbehouden.
        <div v-if="details" style="margin-top:1em">
          BASIS ActivatieMarketing<br>
          Prinsengracht 168<br>
          1016 HA Amsterdam<br>
          KVK 34084610<br>
          Btw NL807997699B01
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setTimeout } from 'timers';

export default {
  name: "Footer",
  props: {},
  data() {
    return {
      details: false
    };
  },
  computed: {
    ...mapGetters([
      'get_window',
    ]),
    currentYear(){
      return new Date().getFullYear();
    }
  },
};
</script>
