<template>
  <div class="location">
    <div class="location__wrapper">
      <template>
        <div class="location__tileimage" :style="location_image" >
        </div>
        <div :class="['location__details_title', 'PrimaryColor', 'CustomFont', location.is_info ? 'hilite':'']">
          <div>{{ location.name }}</div>
        </div>
        <div class="location-place">
          <svg v-if="location.province" class="icon-pin-outline" xmlns="http://www.w3.org/2000/svg" width="11.25" height="15" viewBox="0 0 11.25 15" transform="scale(.8)" style="position:relative;top:2px">
            <path id="Path_59" data-name="Path 59" d="M5.625,2.688A2.812,2.812,0,1,0,8.437,5.5,2.816,2.816,0,0,0,5.625,2.688Zm0,4.687A1.875,1.875,0,1,1,7.5,5.5,1.877,1.877,0,0,1,5.625,7.375Zm0-7.5A5.625,5.625,0,0,0,0,5.5c0,2.268.79,2.9,5.047,9.072a.7.7,0,0,0,1.156,0C10.46,8.4,11.25,7.768,11.25,5.5A5.625,5.625,0,0,0,5.625-.125Zm0,13.885C1.544,7.858.937,7.389.937,5.5a4.687,4.687,0,0,1,8-3.315A4.657,4.657,0,0,1,10.312,5.5C10.312,7.389,9.706,7.858,5.625,13.76Z" transform="translate(0 0.125)" fill="#5a5a5a"/>
          </svg>
          {{location.province}}
        </div>
        <div v-if="location.latlng.length > 5 && location.distance > 0" style="bottom:6px;position:relative;padding-left:10px;font-size:.8em">Afstand: {{ location.distance.toFixed(0) }}&nbsp;km</div>
        <div class="location__details">
          <div class="text-smaller" v-html="location.short_description">
          </div>
        </div>

        <div class="location__options row CustomFont">
          <LoaderButton :class="{'upperCase':true, 'not-visible':hide_vrijkaarten}" :load="false" @action="goToAanvraag"><div>Lespakket</div></LoaderButton>
          <LoaderButton class="alternative upperCase" :load="false" @action="get_info_page(location)"><div>Info</div></LoaderButton>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import LoaderButton from "../components/LoaderButton.vue";

export default {
  name: "LocationTile",
  components: {
    LoaderButton
  },
  props: {
    location: Object
  },
  mounted(){
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if(!entry.isIntersecting) return;

          this.observer.unobserve(entry.target);
          this.visible = true;
        }
      }, {rootMargin: '300px'});

      this.observer.observe(this.$el);
    } else {
      this.visible = true;
    }
  },
  data() {
	return {
		observer: null,
		visible: false
	}
  },
  computed: {
    ...mapGetters([
      'get_config',
      'get_window',
      'get_image_size_multiplier',
      ]),
    hide_vrijkaarten(){
      if(['9272','9250'].indexOf(this.location.id) > -1){
        return true;
      }
      return this.location.is_info;
    },
    location_image() {
      if(!this.visible) return;
      let w = 280;
      let h = 0;
      let url = 'https://cdn.basisam.nl/images/'+this.get_config.images_cdn+'/'+w+'x'+h+'/'+this.location.img;
      let rurl = 'https://cdn.basisam.nl/images/'+this.get_config.images_cdn+'/'+w*2+'x'+h*2+'/'+this.location.img;
      return `background-image: url("${url}");
      background-image: -webkit-image-set(url("${url}") 1x, url("${rurl}") 2x);
      background-image: image-set(url("${url}") 1x, url("${rurl}") 2x);`;
    },
    availability(){
      if(this.location.hasOwnProperty('availability')){
        return this.location.availability;
      }else{
        return false;
      }
    },
    availability_empty(){
      if(this.location.hasOwnProperty('availability')){
        return this.location.availability.empty == '1';
      }else{
        return false;
      }
    },
    has_hardlink(){
      return this.location.hasOwnProperty('hardlink');
    }
  },
  methods: {
    ...mapActions({ get_info: 'get_info' }),
    plaats(item) {
      if(item.hasOwnProperty('locations')){
        if(item.locations.length > 0){
        return 'Meerdere locaties';
        }else{
          return item.selection_title.split(" - ")[0];
        }
      }else{
        return item.selection_title.split(" - ")[0];
      }
    },
    limit_string_length(str) {
      let l = str.length;
      l > 70 ? str.substring(0, 70) + "..." : str.substring(0, 70);
    },
    has_benefit(pricing) {
      return Number(pricing) !== 0;
    },
    has_discount(pricing, percentage) {
      return Number(pricing) !== 2 && Number(percentage) > 0;
    },
    has_two_for_one(pricing) {
      return Number(pricing) === 2;
    },
    get_order_page(location) {
      if(this.has_hardlink){
        window.open(location.hardlink);
      }else{
        var lid = location.id;
        this.get_info(lid).then(() => {
          this.$router.push({path: '/order', query: { lid: `${lid}` }});
        });
      }
    },
    get_info_page(item) {
      var lid = item.id;
      if(item.info_url){
        if(item.info_url.substring(0,8) == 'info?lid'){
          lid = item.info_url.substring(9);
        }else{
          if(item.info_newwindow != "0"){
            window.open(item.info_url);
          }else{
            window.location = item.info_url;
          }
          return;
        }
      }
      this.$router.push({path: '/info', query: { lid: `${lid}` }});
      window.scroll(0,0);
    },
    goToAanvraag(){
      this.$router.push({path: '/aanvragen?lid='+this.location.id});
    },
  }
};
</script>